import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import {AppState} from 'store/customer/storeSetup';
import {Brand} from 'components/customer/AdvancedMaterials/entity/Brand';
import {Finish} from 'components/customer/AdvancedMaterials/entity/Finish';
import {Type} from 'components/customer/AdvancedMaterials/entity/Type';
import {
    Menu,
    MenuItem,
} from 'components/customer/AdvancedMaterials/entity/Menu';
import {groupMaterials} from 'components/customer/AdvancedMaterials/helpers/mappers';
import {Filters} from 'components/customer/AdvancedMaterials/entity/Filters';
import {Door, MaterialType} from 'components/customer/Materials/entity';
import {SearchType} from 'components/customer/AdvancedMaterials/entity/SearchType';

export enum Loader {
    IDLE,
    LOADING_PAGINATION,
    LOADING_DATA,
    LOADING_INITIAL,
}

export enum BrowserType {
    ROOM,
    SETTING,
    PRODUCT,
    BENCHTOP,
    QFP,
}

export type Group<T> = {
    key: string;
    materials: T[];
};

interface AdvancedMaterial {
    favourites: {
        materials: Material[];
    };
    selectedMenu: Menu;
    browserType: BrowserType;

    materialType: Type;
    materialTypes: Type[];

    materialBrands: Brand[];
    materialBrandsEdge: Brand[];

    materialFinishes: Finish[];
    materialFinishesEdge: Finish[];

    materialSearch: string;
    material: Material;
    materials: Group<Material>[];

    edgeSearch: string;
    edge: Material;

    doorSearch: string;
    door: Door;

    hasBrands: boolean;
    hasDoors: boolean;
    hasMaterials: boolean;
    hasEdges: boolean;
    hasFavourites: boolean;
    hasThicknesses: boolean;

    thicknesses: MenuItem[];
    materialColumns: number;

    loading: Loader;
    loadingNewColour: boolean;
    loadingMatchingEdge: boolean;

    currentPage: number;
    totalRecords: number;
    pageSize: number;
    totalPages: number;
    maxIndexSeen: number;

    cabinetType: number;
    materialTypeOption: MaterialType; // this basically denotes if it is carcase or exterior material
    materialIndex: number;

    searchType: SearchType;
}

interface PropertiesAction {
    hasDoors?: boolean;
    hasMaterials?: boolean;
    hasEdges?: boolean;
    hasFavourites?: boolean;
    hasBrands?: boolean;
    hasThicknesses?: boolean;
    browserType?: BrowserType;
    materialTypeOption?: MaterialType;
    materialIndex?: number;
}

const advancedMaterials = createSlice({
    name: 'advancedMaterials',
    initialState: {
        favourites: {
            materials: [],
        },
        materialSearch: '',
        doorSearch: '',
        edgeSearch: '',

        materials: {},

        selectedMenu: Menu.MATERIAL,
        materialTypes: [],
        materialBrands: [],
        materialBrandsEdge: [],
        materialFinishes: [],
        materialFinishesEdge: [],
        material: null,
        materialType: null,

        hasBrands: false,
        hasDoors: false,
        hasMaterials: false,
        hasEdges: false,
        hasFavourites: false,
        hasThicknesses: false,

        thicknesses: [],
        currentPage: 1,
        totalRecords: 0,
        materialColumns: 5,
        pageSize: 35,
        totalPages: 1,
        maxIndexSeen: 0,

        loading: Loader.LOADING_INITIAL,
        loadingMatchingEdge: false,
        loadingNewColour: false,

        edge: null,

        door: null,
        searchType: SearchType.COLOR,
    } as AdvancedMaterial,
    reducers: {
        loadingMatchingEdgeSet: (state, {payload}: PayloadAction<boolean>) => {
            state.loadingMatchingEdge = payload;
        },
        loadingNewColourSet: (state, {payload}: PayloadAction<boolean>) => {
            state.loadingNewColour = payload;
        },
        cabinetTypeSet: (state, {payload}: PayloadAction<number>) => {
            state.cabinetType = payload;
        },
        doorSearchSet: (state, {payload}: PayloadAction<string>) => {
            state.doorSearch = payload;
        },
        edgeSearchSet: (state, {payload}: PayloadAction<string>) => {
            state.edgeSearch = payload;
        },
        edgeSet: (state, {payload}: PayloadAction<Material>) => {
            state.edge = payload;
        },
        doorSet: (state, {payload}: PayloadAction<Door>) => {
            state.door = payload;
        },
        maxIndexSeenSet: (state, {payload}: PayloadAction<number>) => {
            state.maxIndexSeen = payload;
        },
        materialColumnsSet: (state, {payload}: PayloadAction<number>) => {
            state.materialColumns = payload;
        },
        materialSearchSet: (state, {payload}: PayloadAction<string>) => {
            state.materialSearch = payload;
        },
        totalPagesSet: (state, {payload}: PayloadAction<number>) => {
            state.totalPages = payload;
        },
        loadingSet: (state, {payload}: PayloadAction<Loader>) => {
            state.loading = payload;
        },
        totalRecordsSet: (state, {payload}: PayloadAction<number>) => {
            state.totalRecords = payload;
        },
        currentPageSet: (state, {payload}: PayloadAction<number>) => {
            state.currentPage = payload;
        },
        thicknessesSet: (state, {payload}: PayloadAction<MenuItem[]>) => {
            state.thicknesses = payload;
        },
        thicknessSelect: (state, {payload}: PayloadAction<string>) => {
            state.thicknesses = state.thicknesses.map((thickness) => {
                if (thickness.id == payload) {
                    if (
                        thickness.selected &&
                        state.thicknesses.filter((t) => t.selected).length == 1
                    ) {
                        return thickness;
                    }

                    thickness.selected = !thickness.selected;
                } else {
                    thickness.selected = false;
                }

                return thickness;
            });
        },
        doorShowAllSet: (state, {payload}: PayloadAction<string>) => {
            state.materialTypes = state.materialTypes?.map((type) => {
                type.hidden = type.id != payload;
                type.disabled = true;
                type.isViewAll = type.id == payload;

                return type;
            });
        },
        doorShowAllUnset: (state) => {
            state.materialTypes = state.materialTypes?.map((type) => {
                type.hidden = false;
                type.disabled = false;
                type.isViewAll = false;

                return type;
            });
        },
        materialTypeSelect: (state, {payload}: PayloadAction<string>) => {
            if (!state.hasDoors) {
                let selectedType;
                state.materialTypes = state.materialTypes.map((type) => {
                    type.selected = type.id == payload;

                    if (type.selected) {
                        selectedType = type;
                    }

                    return type;
                });
                if (selectedType) {
                    if (state.selectedMenu != Menu.MATERIAL) {
                        state.selectedMenu = Menu.MATERIAL;
                    }

                    state.materialType = selectedType;
                }
            } else {
                for (let i = 0; i < state.materialTypes.length; i++) {
                    if (state.materialTypes[Number(i)].id === payload) {
                        if (state.materialTypes[Number(i)]?.isViewAll) {
                            break;
                        }

                        state.materialTypes[Number(i)].selected =
                            !state.materialTypes[Number(i)].selected;
                        break;
                    }
                }
            }
        },
        materialBrandSelect: (state, {payload}: PayloadAction<string>) => {
            state.materialFinishes = state.materialFinishes.map((finish) => ({
                ...finish,
                selected: false,
            }));
            state.materialBrands = state.materialBrands.map((brand) => {
                if (brand.id == payload) {
                    brand.selected = !brand.selected;
                }

                return brand;
            });
        },
        materialBrandsEdgeSelect: (state, {payload}: PayloadAction<string>) => {
            state.materialFinishesEdge = state.materialFinishesEdge.map(
                (finish) => ({
                    ...finish,
                    selected: false,
                })
            );
            state.materialBrandsEdge = state.materialBrandsEdge.map((brand) => {
                if (brand.id == payload) {
                    brand.selected = !brand.selected;
                }

                return brand;
            });
        },
        materialFinishesSelect: (state, {payload}: PayloadAction<string>) => {
            state.materialFinishes = state.materialFinishes.map((finish) => {
                if (finish.id == payload) {
                    finish.selected = !finish.selected;
                }

                return finish;
            });
        },
        materialFinishesEdgeSelect: (
            state,
            {payload}: PayloadAction<string>
        ) => {
            state.materialFinishesEdge = state.materialFinishesEdge.map(
                (finish) => {
                    if (finish.id == payload) {
                        finish.selected = !finish.selected;
                    }

                    return finish;
                }
            );
        },
        propertiesSet: (state, {payload}: PayloadAction<PropertiesAction>) => {
            const {
                hasDoors = false,
                hasMaterials = false,
                hasEdges = false,
                hasFavourites = false,
                hasBrands = false,
                hasThicknesses = false,
                browserType = BrowserType.PRODUCT,
                materialTypeOption = MaterialType.EXTERIOR,
                materialIndex = 0,
            } = payload;

            state.hasDoors = hasDoors;
            state.hasMaterials = hasMaterials;
            state.hasEdges = hasEdges;
            state.hasFavourites = hasFavourites;
            state.hasBrands = hasBrands;
            state.hasThicknesses = hasThicknesses;
            state.browserType = browserType;
            state.materialTypeOption = materialTypeOption;
            state.materialIndex = materialIndex;
        },
        selectedMenuSet: {
            reducer: (
                state,
                {
                    payload,
                    meta: {resetPage = false},
                }: PayloadAction<Menu, string, {resetPage?: boolean}>
            ) => {
                if (resetPage) {
                    state.currentPage = 1;
                }

                state.selectedMenu = payload;
            },
            prepare: function PrepareSelectedMenu(
                id: Menu,
                resetPage: boolean
            ) {
                return {
                    payload: id,
                    meta: {resetPage},
                };
            },
        },
        favouriteMaterialsSet: (
            state,
            {payload}: PayloadAction<Material[]>
        ) => {
            state.favourites.materials = payload;
        },
        materialTypeSet: (state, {payload}: PayloadAction<Type>) => {
            state.materialType = payload;
        },
        materialTypesSet: (state, {payload}: PayloadAction<Type[]>) => {
            state.materialTypes = payload;
        },
        materialSet: (state, {payload}: PayloadAction<Material>) => {
            state.material = payload;
        },
        materialBrandsSet: (state, {payload}: PayloadAction<Brand[]>) => {
            state.materialBrands = payload;
        },
        materialBrandsEdgeSet: (state, {payload}: PayloadAction<Brand[]>) => {
            state.materialBrandsEdge = payload;
        },
        materialsSet: (state, {payload}: PayloadAction<Material[]>) => {
            state.materials = groupMaterials(payload);
        },
        materialsAdd: (state, {payload}: PayloadAction<Material[]>) => {
            state.materials = groupMaterials(payload, state.materials);
        },
        materialFinishesSet: (state, {payload}: PayloadAction<Finish[]>) => {
            state.materialFinishes = payload;
        },
        materialFinishesEdgeSet: (
            state,
            {payload}: PayloadAction<Finish[]>
        ) => {
            state.materialFinishesEdge = payload;
        },
        searchTypeSet: (state, {payload}: PayloadAction<SearchType>) => {
            state.searchType = payload;
        },
        clearFilters: (state, action: PayloadAction<Filters | undefined>) => {
            const filter = action.payload || Filters.NONE;
            // remember initialize doors, edges for normal materials later

            // This clears brands filter if brand is specified in parameter
            // or no parameter is specified
            if (
                (filter == Filters.BRAND || filter == Filters.NONE) &&
                state.materialBrands.length > 0
            ) {
                state.materialBrands = state.materialBrands.map((brand) => {
                    brand.selected = false;

                    return brand;
                });
            }

            // This clears brands filter for edge if brand is specified in parameter
            if (
                (filter == Filters.BRAND_EDGE || filter == Filters.NONE) &&
                state.materialBrandsEdge.length > 0
            ) {
                state.materialBrandsEdge = state.materialBrandsEdge.map(
                    (brand) => {
                        brand.selected = false;

                        return brand;
                    }
                );
            }

            // This clears finishes filter if finish is specified in parameter
            // or no parameter is specified
            if (
                (filter == Filters.FINISH || filter == Filters.NONE) &&
                state.materialFinishes.length > 0
            ) {
                state.materialFinishes = state.materialFinishes.map(
                    (finish) => {
                        finish.selected = false;

                        return finish;
                    }
                );
            }

            // This clears finishes filter for edge if finish is specified in parameter
            // or no parameter is specified
            if (
                (filter == Filters.FINISH_EDGE || filter == Filters.NONE) &&
                state.materialFinishesEdge.length > 0
            ) {
                state.materialFinishesEdge = state.materialFinishesEdge.map(
                    (finish) => {
                        finish.selected = false;

                        return finish;
                    }
                );
            }

            // This clears material search text if search_material is specified in parameter
            // or no parameter is specified
            if (
                (filter == Filters.SEARCH_MATERIAL || filter == Filters.NONE) &&
                state.materialSearch.length > 0
            ) {
                state.materialSearch = '';
            }

            // This clears door search text if search_door is specified in parameter
            // or no parameter is specified
            if (
                (filter == Filters.SEARCH_DOOR || filter == Filters.NONE) &&
                state.doorSearch.length > 0
            ) {
                state.doorSearch = '';
            }

            if (
                (filter == Filters.SEARCH_EDGE || filter == Filters.NONE) &&
                state.edgeSearch.length > 0
            ) {
                state.edgeSearch = '';
            }

            state.currentPage = 1;
        },
        clearAll: (state) => {
            state.materialBrands = [];
            state.thicknesses = [];
            state.currentPage = 1;
            state.materialFinishes = [];
            state.materialFinishesEdge = [];
            state.materialBrands = [];
            state.materialBrandsEdge = [];
            state.materials = [];
            state.thicknesses = [];
            state.materialTypes = [];
            state.materialSearch = '';
            state.materialColumns = 5;
            state.loadingMatchingEdge = false;
            state.loadingNewColour = false;
            state.loading = Loader.LOADING_INITIAL;
            state.totalRecords = 0;
            state.totalPages = 1;
            state.maxIndexSeen = 0;

            state.hasDoors = false;
            state.hasMaterials = false;
            state.hasEdges = false;
            state.hasFavourites = false;
            state.hasBrands = false;
            state.hasThicknesses = false;
            state.materialTypeOption = undefined;
            state.materialIndex = 0;
            state.searchType = SearchType.COLOR;

            state.edgeSearch = '';
            state.materialSearch = '';
            state.doorSearch = '';
        },
        materialTypesClearAll: (state) => {
            state.materialTypes.forEach((type) => {
                type.selected = false;
            });
        },
    },
});

export const selectSearchType = (store: AppState) =>
    store.advancedMaterials.searchType;

export const selectMaterialType = (store: AppState) =>
    store.advancedMaterials.materialType;
export const selectMaterialIndex = (store: AppState) =>
    store.advancedMaterials.materialIndex;
export const selectMaterialTypeOption = (store: AppState) =>
    store.advancedMaterials.materialTypeOption;
export const selectMaxIndexSeen = (store: AppState) =>
    store.advancedMaterials.maxIndexSeen;
export const selectTotalPages = (store: AppState) =>
    store.advancedMaterials.totalPages;
export const selectPageSize = (store: AppState) =>
    store.advancedMaterials.pageSize;
export const selectTotalRecords = (store: AppState) =>
    store.advancedMaterials.totalRecords;
export const selectCurrentPage = (store: AppState) =>
    store.advancedMaterials.currentPage;
export const selectBrowserType = (store: AppState) =>
    store.advancedMaterials.browserType;

export const selectEdge = (store: AppState) => store.advancedMaterials.edge;
export const selectDoor = (store: AppState) => store.advancedMaterials.door;

export const selectMaterialSearch = (store: AppState) =>
    store.advancedMaterials.materialSearch;
export const selectDoorSearch = (store: AppState) =>
    store.advancedMaterials.doorSearch;
export const selectEdgeSearch = (store: AppState) =>
    store.advancedMaterials.edgeSearch;

export const selectMaterialFinishes = (store: AppState) =>
    store.advancedMaterials.materialFinishes;
export const selectMaterialFinishesEdge = (store: AppState) =>
    store.advancedMaterials.materialFinishesEdge;

export const selectMaterialBrands = (store: AppState) =>
    store.advancedMaterials.materialBrands;
export const selectMaterialBrandsEdges = (store: AppState) =>
    store.advancedMaterials.materialBrandsEdge;

export const selectLoading = (store: AppState) =>
    store.advancedMaterials.loading;
export const selectLoadingNewColour = (store: AppState) =>
    store.advancedMaterials.loadingNewColour;
export const selectLoadingMatchingEdge = (store: AppState) =>
    store.advancedMaterials.loadingMatchingEdge;

export const selectCabinetType = (store: AppState) =>
    store.advancedMaterials.cabinetType;
export const selectMaterialColumns = (store: AppState) =>
    store.advancedMaterials.materialColumns;
export const selectThicknesses = (store: AppState) =>
    store.advancedMaterials.thicknesses;
export const selectMenu = (store: AppState) =>
    store.advancedMaterials.selectedMenu;
export const selectMaterial = (store: AppState) =>
    store.advancedMaterials.material;
export const selectMaterialTypes = (store: AppState) =>
    store.advancedMaterials.materialTypes;
export const selectHasDoors = (store: AppState) =>
    store.advancedMaterials.hasDoors;
export const selectHasMaterials = (store: AppState) =>
    store.advancedMaterials.hasMaterials;
export const selectHasEdges = (store: AppState) =>
    store.advancedMaterials.hasEdges;
export const selectHasFavourites = (store: AppState) =>
    store.advancedMaterials.hasFavourites;
export const selectHasThicknesses = (store: AppState) =>
    store.advancedMaterials.hasThicknesses;
export const selectMaterials = (store: AppState) =>
    store.advancedMaterials.materials;
export const selectMaterialByIndex = createSelector(
    [selectMaterials, (_: AppState, index: number) => index],
    (materials, index) => {
        if (materials.length > 0) {
            const group = materials[Number(index)];

            if (group) {
                return group.materials;
            }
        }
    }
);
export const selectBrands = createSelector(
    [(state: AppState) => state.advancedMaterials.materialBrands],
    (brands) => {
        return brands.filter((brand) => brand.selected);
    }
);
export const selectBrandIds = createSelector([selectBrands], (brands) => {
    return brands.filter((brand) => brand.selected).map((brand) => brand.id);
});

const selectBrandsEdge = createSelector(
    [(state: AppState) => state.advancedMaterials.materialBrandsEdge],
    (brands) => {
        return brands.filter((brand) => brand.selected);
    }
);
export const selectBrandsEdgeIds = createSelector(
    [selectBrandsEdge],
    (brands) => {
        return brands
            .filter((brand) => brand.selected)
            .map((brand) => brand.id);
    }
);

export const selectFinishes = createSelector(
    [selectMaterialFinishes],
    (finishes) => {
        return finishes.filter((finish) => finish.selected);
    }
);
export const selectFinishIds = createSelector([selectFinishes], (finishes) => {
    return finishes
        .filter((finish) => finish.selected)
        .map((finish) => finish.id);
});

export const selectFinishesEdge = createSelector(
    [selectMaterialFinishesEdge],
    (finishes) => {
        return finishes.filter((finish) => finish.selected);
    }
);
export const selectFinishEdgeIds = createSelector(
    [selectFinishesEdge],
    (finisnes) => {
        return finisnes
            .filter((finish) => finish.selected)
            .map((finish) => finish.id);
    }
);

export const selectThickness = createSelector(
    [selectThicknesses],
    (thicknesses) => {
        const selectedThicknesses = thicknesses
            .filter((thickness) => thickness.selected)
            .map((thickness) => thickness.id);

        if (selectedThicknesses.length > 0) {
            return selectedThicknesses[0];
        }
    }
);

export const {
    loadingSet,
    thicknessesSet,
    materialsSet,
    materialsAdd,
    materialSet,
    propertiesSet,
    selectedMenuSet,
    materialTypeSet,
    materialBrandsSet,
    materialBrandSelect,
    materialFinishesSet,
    materialFinishesSelect,
    thicknessSelect,
    currentPageSet,
    materialSearchSet,
    clearFilters,
    clearAll,
    materialColumnsSet,
    totalRecordsSet,
    totalPagesSet,
    maxIndexSeenSet,
    doorSet,
    edgeSet,
    materialTypesSet,
    materialTypeSelect,
    doorSearchSet,
    edgeSearchSet,
    doorShowAllSet,
    doorShowAllUnset,
    materialFinishesEdgeSet,
    materialFinishesEdgeSelect,
    cabinetTypeSet,
    materialBrandsEdgeSet,
    materialBrandsEdgeSelect,
    loadingMatchingEdgeSet,
    loadingNewColourSet,
    searchTypeSet,
    materialTypesClearAll,
} = advancedMaterials.actions;

export default advancedMaterials.reducer;
